<template>
  <div class="zhongSanContainer">
    <Title></Title>
    <div class="banner">
    </div>
    <div class="schoolDesc">
      <div class="mianbaoxie">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" ><span style="color:#D70C0C;font-weight:400">首页</span></el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/typicalCase/typeCase'}"><span style="color:#D70C0C;font-weight:400">典型案例</span></el-breadcrumb-item>
        <el-breadcrumb-item>中山大学</el-breadcrumb-item>
      </el-breadcrumb>
      </div>
          <h4 class="descH4">高校简介</h4>
          <span class="line"></span>
          <p style="font-size:16px;font-weight:400;color:#222;line-height:32px;width:1100px;text-align:left;padding-top:40px;text-indent:32px">中山大学由孙中山先生创办，有着一百多年办学传统。作为中国教育部直属高校，通过部省共建，中山大学已经成为一所国内一流、国际知名的现代综合性大学。现由广州校区、珠海校区、深圳校区三个校区、五个校园及十家附属医院组成。中山大学正在向世界一流大学迈进，努力成为全球学术重镇。
      </p>
          <p style="font-size:16px;font-weight:400;color:#222;line-height:32px;width:1100px;text-align:left;text-indent:32px">中山大学具有人文社科和理医工多学科厚实基础，不断追求学术创新，以国际视野开放办学，现已形成了“综合性、研究型、开放式”的特色。学校坚持中国特色社会主义办学方向，坚持立德树人根本，以“面向世界科技前沿、面向经济主战场、面向国家重大需求、面向人民生命健康”为基本导向；树立了“三校区五校园”错位发展、合力支撑的发展思路。学校正在努力推进由外延式发展向内涵式发展转变，由常规发展向主动发展转变，由文理医优势向文理医工各具特色、融合发展转变。现在，中山大学正站在新的起点上，为稳居国内高校第一方阵，建设世界一流大学的目标努力奋斗！</p>
    </div>
    <div class="construction center">
      <h4 class="descH4">建设挑战</h4>
      <span class="line"></span>
      <div class="constructionDesc">
        <ThreeCard :threeCard="constructionDesc" class="constructionDescCard"></ThreeCard>
      </div>
    </div>
    <div class="plan center">
      <h4 class="descH4">总体规划</h4>
      <span class="line"></span>
      <p style="color:#444;font-size:16px;margin-top:20px;width:1100px;text-align:center;line-height: 28px;">017年，易普行开始参与中山大学人力资源信息化的规划和建设，项目建设采取整体规划、分期建设、逐步实施、持续优化的实施模式。以数据为核心，各个模块设立数据入口，在建设由数据标准体系及其支撑环境所构成的全域教职工数据库基础上，推动整个人事信息化服务体系的完善与发展。这种模式激发了教职工“人人有责”的强大的驱动力，真正实现了“用起来”。</p>
      <div class="planDesc">
        <ThreeCard :threeCard="planDesc" class="planDescCard"></ThreeCard>
      </div>
    </div>
    <div class="step center">
        <ShowCard
        :cardContent="step"
        class="technicalServeShowCard"
      ></ShowCard>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
export default {
  name: 'ZhongSan',
  data () {
    return {
      constructionDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/one.png'),
          desc: [
            { p: '教职工众多且分散' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/two.png'),
          desc: [
            { p: '人事业务复杂且事务繁多' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/three.png'),
          desc: [
            { p: '人事制度、流程多变' }
          ]
        }
      ],
      planDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/data.png'),
          title: '数据层面',
          desc: [
            { p: '实行数据责任制，数据管理人人有责' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/yewu.png'),
          title: '业务层面',
          desc: [
            { p: '根据学校的需求定制开发业务模块，业务模式高度匹配；' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/serve.png'),
          title: '服务层面',
          desc: [
            { p: '为教职工提供便捷的自助服务，服务获取随时随地，提升学校整体人力资源管理与服务能力。' }
          ]
        }
      ],
      step: {
        title: '建设成果',
        p: '经过四期规划与建设，实现并建立中山大学人力资源展现中心、数据中心、业务中心、支撑中心。',
        content: [
          {
            title: '人力资源展现中心',
            desc: '通过官网、微信公众号、网上服务大厅等渠道展现中山大学人力资源信息化应用成效，并充分利用现有入口资源，按不同用户习惯组织服务，泛在化获取服务。',
            img: require('@/assets/solute/SmartCampus/smartOne.png'),
            position: 'top:10;left:0'
          },
          {
            title: '人力资源数据中心',
            desc: '建立全面、统一的教职工信息库，通过科学的数据采集和数据管理机制，多渠道采集、整合、维护和存储与教职工相关的人事、教学、科研等360度全方位数据信息。',
            img: require('@/assets/solute/SmartCampus/smartTwo.png'),
            position: 'top:0;right:0'
          },
          {
            title: '人力资源业务中心',
            desc: '围绕教职工全生命周期人事业务进行管理与服务，实现教职工入校、在校、离校全生命周期业务管理。并以业务为抓手，进一步提升数据质量。',
            img: require('@/assets/solute/SmartCampus/smartThree.png'),
            position: 'top:190px;left:0'
          },
          {
            title: '人力资源支撑中心',
            desc: '建立项目管理机制，规范工作及业务标准，并建设完备的角色权限体系。',
            img: require('@/assets/solute/SmartCampus/smartFour.png'),
            position: 'top:190px;right:0'
          }
        ]
      }
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moCooperation/moZhongSan')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped>
.zhongSanContainer{
    padding-top: 80px;
    min-width: 1200px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
 height: 360px;
  background: url("~@/assets/TypicalCase/zhongsan/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.schoolDesc{
    width: 100%;
height: 452px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.construction {
  width: 100%;
height: 480px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.constructionDesc {
  width: 1200px;
}
::v-deep .constructionDescCard .threeCardContent li{
    height: 200px;
}
::v-deep .constructionDescCard .threeCardContent li p{
font-size: 18px;
font-weight: 400;
color: #222222;
line-height: 32px;
margin-top: 20px;
}
.plan {
  width: 100%;
height: 656px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.planDesc {
  width: 1200px;
}
.step{
    width: 100%;
height: 624px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.technicalServeShowCard {
  height: 100%;
}
::v-deep .technicalServeShowCard .showCardContent .left img {
  width: 68px;
  height: 68px;
}
::v-deep .technicalServeShowCard .showCardContent .right {
  width: 432px;
}
::v-deep .technicalServeShowCard .showCardContent .right p{
font-size: 16px;
}

.mianbaoxie{
  width: 1200px;
  margin-top: 20px;
}
</style>
